<template>
  <div
    class="homepage-slider"
    :class="{ 'homepage-slider--parent': hasChildSiteTeasers }"
    data-test-id="homepage-slider"
  >
    <span ref="liveregion" class="visually-hidden" aria-live="polite" aria-atomic="true" />
    <vue-agile
      ref="slider"
      :dots="showDots"
      @before-change="updateLiveRegion"
      @after-change="makeInactiveSlidesInert($refs.slider)"
    >
      <div
        v-for="(post, index) in posts"
        :key="post.id"
      >
        <post-teaser
          :post="post"
          :responsive-images="true"
          :show-masthead="hasChildSiteTeasers"
          :loading="index > 0 ? 'lazy' : 'eager'"
          featured-image-size="Large"
          secondary-image-size="HeroSlider"
        />
      </div>
      <icon-component slot="nextButton" name="caret" title="next" />
      <icon-component slot="prevButton" name="caret" title="previous" />
    </vue-agile>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';
import PostTeaser from '@/components/Posts/PostTeaser.vue';
import slidesInertMixin from '@/mixins/slides-inert-mixin';

export default {
  name: 'HomepageSlider',
  components: {
    VueAgile,
    PostTeaser,
  },
  mixins: [slidesInertMixin],
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showDots: false,
    };
  },
  computed: {
    hasChildSiteTeasers() {
      return this.$store.getters.siteHasFeature('Child Site Teasers');
    },
  },
  mounted() {
    // We want to showDots but SSR hydration fails because vue-agile will
    // just render the first slide, what a pain. Hopefully this is a temporary fix
    // ideally we can use the default showDots="true"
    this.showDots = true;
    this.makeInactiveSlidesInert(this.$refs.slider);
    this.makeAllCloneSlidesInert(this.$refs.slider);
    this.addNavButtonAriaLabels();
  },
  methods: {
    addNavButtonAriaLabels() {
      const { slider } = this.$refs;
      const prevButton = slider.$el.querySelector('.agile__nav-button--prev');
      const nextButton = slider.$el.querySelector('.agile__nav-button--next');
      prevButton.setAttribute('aria-label', 'previous slide');
      nextButton.setAttribute('aria-label', 'next slide');
    },
    buildGtmLabel(teaser, currentIndex) {
      return [
        teaser.data('teaserPostId'),
        teaser.data('teaserPostType'),
        currentIndex,
      ].join('_');
    },
    getTeaserForIndex(currentIndex) {
      return this.posts[currentIndex].find('.post-teaser');
    },
    announceChange() {
      const { slider } = this.$refs;
      const currentIndex = slider.getCurrentSlide();
      const teaser = slider.getTeaserForIndex(currentIndex);
      window.dataLayer.push({
        event: 'hu.event',
        category: 'homepage_carousel',
        label: this.buildGtmLabel(teaser, currentIndex),
        action: 'click',
        value: this.posts.count,
      });
    },
    updateLiveRegion(event) {
      const { liveregion } = this.$refs;
      liveregion.textContent = `Item ${event.nextSlide + 1} of ${this.posts.length}`;
    },
  },
};
</script>

<style lang="scss"> // no scoped - for nested agile slider classes
  @import '@/stylesheets/components/_homepage-slider';
</style>

<docs>
</docs>
